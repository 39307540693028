import utility from '@/common/utility'
import auth from '@/common/auth'

export default class PlanStatExaminationGroupModel {
  constructor (props) {
    if (props) {
      this.resourceId = props.planStatExaminationGroupGuid
      this.planStatExaminationGroupGuid = props.planStatExaminationGroupGuid
      this.tenderGuid = props.tenderGuid
      this.examinationType = props.examinationType
      this.approvalUserGuid = props.approvalUserGuid
      this.approvalStatus = props.approvalStatus
      this.flowGuid = props.flowGuid
      this.year = props.year
      this.month = props.month
      this.date = props.date
      this.sortId = props.sortId
      this.createUserGuid = props.createUserGuid
      this.createTime = props.createTime
      this.startDate = props.startDate
      this.startDate = props.endDate
      this.startDate = props.submitDate
    }
    this.init()
  }

  init () {
    if (!this.resourceId) this.resourceId = ''
    if (!this.planStatExaminationGroupGuid) this.planStatExaminationGroupGuid = ''
    if (!this.tenderGuid) this.tenderGuid = ''
    if (!this.examinationType) this.examinationType = ''
    if (!this.approvalUserGuid) this.approvalUserGuid = ''
    if (!this.approvalStatus) this.approvalStatus = ''
    if (!this.flowGuid) this.flowGuid = ''
    if (!this.year) this.year = 0
    if (!this.month) this.month = 0
    if (!this.date) this.date = 0
    if (!this.sortId) this.sortId = 0
    if (!this.createUserGuid) this.createUserGuid = auth.getUserInfo().userGuid
    if (!this.createTime) this.createTime = utility.getCurrentTime()
  }

  generatePrimaryKey () {
    this.planStatExaminationGroupGuid = utility.getUuid()
    this.resourceId = this.planStatExaminationGroupGuid
  }
}
