import RestService from '@/services/RestService'
import utility from '@/common/utility'
import axios from 'axios'
import auth from '@/common/auth'

let resourceName = 'planStatExaminationGroup'
let getNextUsersUrl = 'getNextUsers'
let transferUrl = 'transfer'
let finalSubmitUrl = 'finalSubmit'
let cancelFinalSubmitUrl = 'cancelFinalSubmit'
let deleteDateStatUrl = 'deleteDateStat'
let deleteGroupUrl = 'deleteGroup'
let updateDatesUrl = 'updateDates'

class PlanStatExaminationGroupService extends RestService {
  getUsers (planStatExaminationGroupGuid) {
    return axios.get(utility.getRestFullUrl(this.resourceName, getNextUsersUrl), {
      params: {
        planStatExaminationGroupGuid
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  transfer (userGuid, stepName, flowGuid, examinationGroupGuid, flowSortId) {
    return axios.post(utility.getRestFullUrl(this.resourceName, transferUrl), utility.getFormData({
      userGuid,
      stepName,
      flowGuid,
      examinationGroupGuid,
      flowSortId
    }), {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  updateDates ({ examinationGroupGuid, startDate, endDate, submitDate }) {
    console.log('updateDates', examinationGroupGuid, startDate, endDate, submitDate)
    return axios.post(utility.getRestFullUrl(this.resourceName, examinationGroupGuid, updateDatesUrl), {
      startDate,
      endDate,
      submitDate
    }, {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  finalSubmit (examinationGroupGuid) {
    return axios.post(utility.getRestFullUrl(this.resourceName, finalSubmitUrl), utility.getFormData({
      examinationGroupGuid
    }), {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  cancelFinalSubmit (projectGuid, examinationGroupGuid) {
    return axios.post(utility.getRestFullUrl(this.resourceName, cancelFinalSubmitUrl), null, {
      params: {
        projectGuid,
        planStatExaminationGroupGuid: examinationGroupGuid
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  deleteGroup (planStatExaminationGroupGuid) {
    return axios.post(utility.getRestFullUrl(this.resourceName, deleteGroupUrl), null, {
      params: {
        planStatExaminationGroupGuid
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  deleteDateStat (planStatExaminationGroupGuid) {
    return axios.post(utility.getRestFullUrl(this.resourceName, deleteDateStatUrl), utility.getFormData({
      planStatExaminationGroupGuid
    }), {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }
}

const planStatExaminationGroupService = new PlanStatExaminationGroupService(resourceName)

export default planStatExaminationGroupService
